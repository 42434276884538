import CurrencyList from "./CurrencyList";
import {useEffect, useState} from "react";
import axios from "axios";

export default function UserGiveExchange({exchangeDataFrom, setExchangeDataFrom, currencies, leftLabel, showMin, currenciesTabs}) {

    const [value, setValue] = useState(0)

    return (
        <div className={'p-3 border rounded-xl m-2'}>
            <div className={'flex-col gap-10'}>
                <div className={'w-full flex justify-between gap-5'}>
                    <p className={'text-sm text-[#A9A9AC] w-1/2'}>{leftLabel}</p>

                    {
                        showMin && (
                            <p className={'text-sm w-1/2 ' + (Number(value).toFixed(3) >= Number(exchangeDataFrom.minAmount).toFixed(3) ? 'text-[#A9A9AC]' : 'text-red-500')}>Min: {Number(exchangeDataFrom.minAmount).toFixed(3)}</p>
                        )
                    }

                </div>
                <div className={'w-full flex justify-between gap-5'}>
                    <div>
                        <input className={'font-bold text-5xl w-full w-1/2'} placeholder={0} type={"number"}
                               onInput={(e) => {
                                   setExchangeDataFrom({...exchangeDataFrom, value: e.target.value})
                                   setValue(e.target.value)
                               }} value={exchangeDataFrom.value}></input>
                    </div>

                    <CurrencyList currency={'Bitcoin'} symbol={'BTC'} currencies={currencies}
                                  exchangeData={exchangeDataFrom} setExchangeData={setExchangeDataFrom} tabs={currenciesTabs}/>
                    {/*exchangeData={exchangeDataFrom} setExchangeData={setExchangeDataFrom}*/}
                </div>
            </div>
        </div>
    )
}