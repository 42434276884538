import {useEffect, useState} from "react";

export default function CurrencyList({currency, symbol, currencies, exchangeData, setExchangeData, tabs}) {
    const [selectedCurrency, setSelectedCurrency] = useState(currency);
    const [selectedSymbol, setSelectedSymbol] = useState(symbol);

    const [activeTab, setActiveTab] = useState(tabs[Object.keys(tabs)[0]])

    const [listIsOpen, setListIsOpen] = useState(false);

    // useEffect(() => {
    //      return () => {setActiveTab(tabs[Object.keys(tabs)[0]])}
    // }, [tabs]);

    return (
        <div className={'w-full relative'}>
            <div className={'p-3 bg-[#e6efff] w-full rounded-xl flex justify-between items-center cursor-pointer'}
                 onClick={() => setListIsOpen(!listIsOpen)}>
                <div>
                    {exchangeData && (
                        <div className={'flex gap-5 items-center'}>
                            {
                                exchangeData.icon && (<img src={'https://buttim.org' + exchangeData.icon} className={'h-[30px]'}/>)
                            }
                            <div>
                                <p><b>{exchangeData.name}</b></p>
                                <p>{exchangeData.symbol}</p>
                            </div>

                        </div>
                    )

                    }

                </div>
                <div>
                    <p className={'text-sm'}>Изменить</p>
                </div>
            </div>
            {
                listIsOpen && (
                    <div
                        className={'absolute pt-3 pb-3 z-40 bg-[#c4d9ff] w-full rounded-xl flex-col gap-3 justify-between items-center'}>
                        <div className={'flex gap-3 p-3'}>
                            {
                                Object.keys(tabs).map((key) =>
                                    <div className={'p-2 text-black rounded-xl cursor-pointer ' + (tabs[key] === activeTab ? 'bg-white' : 'bg-[#5793ff]')} onClick={() => {
                                        setActiveTab(tabs[key])
                                    }}>
                                        <p>{key}</p>
                                    </div>
                                )
                            }
                        </div>
                        <div>
                            {
                                currencies[activeTab].map((item) =>
                                    <div
                                        className={'flex items-center gap-5 mb-3 hover:bg-[#9ec1ff] transition-all p-2 rounded-xl cursor-pointer'}
                                        onClick={() => {
                                            setListIsOpen(false);
                                            setExchangeData({...exchangeData, name: item.name, symbol: item.symbol, icon: item.icon, type: activeTab, minAmount: item.minAmount})
                                        }}>
                                        <img src={'https://buttim.org' + item.icon} className={'h-[30px]'}/>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }

        </div>
    )
}