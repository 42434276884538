import logo from '../logo.svg';
import '../App.css';
import UserGiveExchange from "../components/UserGiveExchange";
import UserGetExchange from "../components/UserGetExchange";
import InputField from "../components/InputField";
import PrimaryButton from "../components/PrimaryButton";
import {useEffect, useState} from "react";
import axios from "axios";
import 'boxicons'
import CurrencyList from "../components/CurrencyList";
import MultiPathSelector from "../components/MultiPathSelector";
import HeadMenu from "../components/headmenu";

function App() {

    const [currencies, setCurrencies] = useState({})
    const [sourcePayment, setSourcePayment] = useState("")
    const [targetPayment, setTargetPayment] = useState("")
    const [email, setEmail] = useState("")

    const [tillUpdate, setTillUpdate] = useState(60)

    const [selectedExchangeFrom, setSelectedExchangeFrom] = useState({
        name: "", symbol: "", value: 0, icon: "", type: "", minAmount: 0, allow: false, payment: ''
    })

    const [selectedExchangeTo, setSelectedExchangeTo] = useState({
        name: "", symbol: "", value: 0, icon: "", type: "", minAmount: 0, payment: ''
    })

    const [replaceDataFrom, setReplaceDataFrom] = useState({
        name: "",
        symbol: "",
        value: 0,
        icon: "",
        type: "",
        minAmount: 0,
        allow: false,
        payment: 'С вами свяжется оператор'
    })

    const [replaceDataTo, setReplaceDataTo] = useState({
        name: "",
        symbol: "",
        value: 0,
        icon: "",
        type: "",
        minAmount: 0,
        allow: false,
        payment: 'С вами свяжется оператор'
    })

    const [replaceEmail, setReplaceEmail] = useState("")

    const [replaceCurrency, setReplaceCurrency] = useState({})

    const switchFields = () => {
        let tmpName = selectedExchangeFrom.name
        let tmpSymbol = selectedExchangeFrom.symbol
        let tmpValue = selectedExchangeFrom.value
        let tmpIcon = selectedExchangeFrom.icon
        let tmpType = selectedExchangeFrom.type

        setSelectedExchangeFrom({
            ...selectedExchangeFrom,
            name: selectedExchangeTo.name,
            symbol: selectedExchangeTo.symbol,
            value: selectedExchangeTo.value,
            icon: selectedExchangeTo.icon,
            type: selectedExchangeTo.type
        })
        setSelectedExchangeTo({...selectedExchangeTo, name: tmpName, symbol: tmpSymbol, icon: tmpIcon, type: tmpType})
    }

    const [replaceFrom, setReplaceFrom] = useState(null)
    const [replaceTo, setReplaceTo] = useState(null)

    const [replaceAmount, setReplaceAmount] = useState(0)

    const [replaceContact, setReplaceContact] = useState(null)
    const [exchangeError, setExchangeError] = useState("")

    const [exchangeCompleted, setExchangeCompleted] = useState(false)

    const [action, setAction] = useState("replace")

    const sendExchangeRequest = () => {

        document.querySelector('#exchange-btn').innerHTML = "<box-icon name='loader-alt' animation='spin' color='#ffffff' ></box-icon>"

        let exdata = {
            sourceCurrency: selectedExchangeFrom.name,
            targetCurrency: selectedExchangeTo.name,
            sourceAmount: selectedExchangeFrom.value,
            targetAmount: selectedExchangeTo.value,
            sourcePaymentMethod: sourcePayment,
            targetPaymentMethod: targetPayment,
            email: email,
            tg: ''
        }

        console.log(sourcePayment)
        console.log(targetPayment)
        console.log(exdata)

        axios.post("https://buttim.org/api/exchange/request", exdata)
            .then((resp) => {
                let res = resp.data

                setExchangeCompleted(res['ok'])

                if (res['ok'] === false) {
                    setExchangeError(res['verbose'])
                }

                document.querySelector('#exchange-btn').innerHTML = "Обменять"
            })
    }


    const sendReplaceRequest = () => {

        document.querySelector('#replace-btn').innerHTML = "<box-icon name='loader-alt' animation='spin' color='#ffffff' ></box-icon>"

        let exdata = {
            replaceFromRaw: replaceFrom,
            replaceToRaw: replaceTo,
            replaceAmount: replaceAmount,
            replaceEmail: replaceEmail,
            replaceContact: replaceContact
        }

        console.log(exdata)

        axios.post("https://buttim.org/api/replace/request/", exdata)
            .then((resp) => {
                let res = resp.data

                setExchangeCompleted(res['ok'])

                if (res['ok'] === false) {
                    setExchangeError(res['verbose'])
                }

                document.querySelector('#replace-btn').innerHTML = "Оставить заявку"
            })
    }


    const [exchangeRate, setExchangeRate] = useState(null)

    useEffect(() => {
        if (selectedExchangeTo.symbol !== "" && selectedExchangeFrom.symbol !== "") {
            axios.get(`https://buttim.org/api/quotes/currency?source=${selectedExchangeFrom.symbol}&target=${selectedExchangeTo.symbol}`)
                .then((resp) => {
                    setExchangeRate(resp.data)
                    setTillUpdate(60)
                })
        }
    }, [selectedExchangeFrom.symbol, selectedExchangeTo.symbol]);

    useEffect(() => {
        if (exchangeRate !== null) {
            let p = exchangeRate.action === "buy" ? -1 : 1
            setSelectedExchangeTo({
                ...selectedExchangeTo,
                value: (Number(selectedExchangeFrom.value) * Math.pow(Number(exchangeRate.price), p)).toFixed(3)
            })
            console.log(selectedExchangeFrom)
            console.log(Number(selectedExchangeFrom.value) * Math.pow(Number(exchangeRate.price), p))

        }
    }, [exchangeRate, selectedExchangeFrom.value]);

    useEffect(() => {
        if (tillUpdate === 0) {
            if (selectedExchangeTo.symbol !== "" && selectedExchangeFrom.symbol !== "") {
                axios.get(`https://buttim.org/api/quotes/currency?source=${selectedExchangeFrom.symbol}&target=${selectedExchangeTo.symbol}`)
                    .then((resp) => {
                        setExchangeRate(resp.data)
                    })
            }
            setTillUpdate(60)
        }
    }, [tillUpdate]);

    useEffect(() => {
        axios.get('https://buttim.org/api/currencies')
            .then((resp) => {
                let data = resp.data
                setCurrencies(data)

                axios.get('https://buttim.org/api/replace/currencies')
                    .then((data) => {
                        setReplaceCurrency(data.data)

                        const interval = setInterval(() => {
                            setTillUpdate((prev) => prev - 1);
                        }, 1000);

                        //Clearing the interval
                        return () => clearInterval(interval);
                    })


            })
    }, []);

    return (
        <div className="m-auto container">
            <HeadMenu />
            <div className={'flex justify-center gap-10 items-center w-full h-screen'}>
                {
                    action === "exchange" && (
                        <div className={'w-1/2'}>

                            <div className={' p-5 bg-white rounded-xl gradient-shadow'}>
                                <div className={'m-2 p-4 rounded-xl w-100 bg-[#def8ff] flex justify-between items-center '}>
                                    {exchangeRate ? (

                                        exchangeRate.ok && (<>
                                                <p>1 {exchangeRate.marketName.split('/')[0]} = {Number(exchangeRate.price).toFixed(3)} {exchangeRate.marketName.split('/')[1]}</p>
                                                <p>Курс обновится через {tillUpdate} c.</p>
                                            </>

                                        )


                                    ) : (<>
                                        Выберите обмен
                                    </>)}
                                </div>

                                <UserGiveExchange exchangeDataFrom={selectedExchangeFrom}
                                                  setExchangeDataFrom={setSelectedExchangeFrom}
                                                  currencies={currencies}
                                                  leftLabel={'Вы отдаете'}
                                                  allow={selectedExchangeFrom.allow}
                                                  showMin={true}
                                                  currenciesTabs={{
                                                      "Крипта": "crypto",
                                                      "Валюта": "card"
                                                  }}/>

                                {
                                    selectedExchangeFrom.symbol && selectedExchangeTo.symbol && (
                                        <button onClick={switchFields}
                                                className={'m-2 bg-[#4589ff] text-white font-bold rounded-xl hover:bg-[#a1c3ff] transition-all leading-none p-2 absolute left-1/2 -translate-x-1/2 -translate-y-3/4'}>
                                            <box-icon name='refresh' color='#ffffff'></box-icon>
                                        </button>
                                    )
                                }


                                <UserGiveExchange exchangeDataFrom={selectedExchangeTo}
                                                  setExchangeDataFrom={setSelectedExchangeTo}
                                                  currencies={currencies}
                                                  leftLabel={'Вы получаете'}
                                                  currenciesTabs={{
                                                      "Крипта": "crypto",
                                                      "Валюта": "card"
                                                  }}/>


                                {exchangeRate && (exchangeRate.ok ? (<>
                                        {(selectedExchangeFrom.type === "card" && selectedExchangeTo.type === "crypto") && (
                                            <div>
                                                <InputField placeholder={`Номер карты ${selectedExchangeFrom.name}`}
                                                            setSource={setSourcePayment}/>
                                                <InputField placeholder={`Номер кошелька ${selectedExchangeTo.name}`}
                                                            setSource={setTargetPayment}/>
                                            </div>)}

                                        {(selectedExchangeFrom.type === "crypto" && selectedExchangeTo.type === "card") && (
                                            <div>
                                                <InputField placeholder={`Номер карты ${selectedExchangeTo.name}`}
                                                            setSource={setTargetPayment}/>
                                            </div>)}

                                        <div>
                                            <InputField placeholder={'E-mail'} setSource={setEmail}/>
                                        </div>

                                        <button
                                            className={'p-5 w-full m-2 bg-[#4589ff] text-white font-bold rounded-xl hover:bg-[#a1c3ff] transition-all'}
                                            id={'exchange-btn'} onClick={sendExchangeRequest}>Обменять!
                                        </button>
                                    </>

                                ) : (<div className={'p-4 bg-red-300 m-2 rounded-xl'}>
                                    <p className={'text-3xl text-center'}>Обмен невозможен</p>
                                </div>))}
                            </div>
                            {exchangeCompleted ? (
                                <div className={'bg-white text-black p-5 rounded-2xl mt-5 flex items-center gap-5'}>
                                    <box-icon name='check-circle' type='solid' animation='tada' color='#33fd27'></box-icon>
                                    <p><b>Заявка создана.</b> Информация отправлена на ваш Email.</p>
                                </div>) : (exchangeError && (
                                <div className={'bg-white text-black p-5 rounded-2xl mt-5 flex items-center gap-5'}>
                                    <box-icon name='block' animation='tada' color='#fd4927'></box-icon>
                                    <p><b>Ошибка при обработке запроса.</b> {exchangeError}</p>
                                </div>))}

                        </div>
                    )
                }

                {
                    action === "replace" && (
                        <div className={'w-1/2'}>

                            <div className={' p-5 bg-white rounded-xl gradient-shadow'}>
                                <p className={'text-sm text-[#A9A9AC] w-1/2 m-2'}>Вы отдаете</p>
                                <MultiPathSelector pathsContainer={replaceCurrency.give} setSource={setReplaceFrom}/>
                                <input className={'text-5xl w-full w-1/2 font-bold mt-5 mb-5'} placeholder={0}
                                       type={"number"} onInput={(e) => setReplaceAmount(Number(e.target.value))}></input>

                                <p className={'text-sm text-[#A9A9AC] w-1/2 m-2'}>Вы хотите получить</p>
                                <MultiPathSelector pathsContainer={replaceCurrency.get} setSource={setReplaceTo}/>

                                <InputField placeholder={'Ваш Email'} setSource={setReplaceEmail}/>
                                <InputField placeholder={'Номер телефона для связи'} setSource={setReplaceContact}/>

                                <PrimaryButton onClick={sendReplaceRequest} id={'replace-btn'}>Отправить
                                    заявку</PrimaryButton>

                            </div>
                            {exchangeCompleted ? (
                                <div className={'bg-white text-black p-5 rounded-2xl mt-5 flex items-center gap-5'}>
                                    <box-icon name='check-circle' type='solid' animation='tada' color='#33fd27'></box-icon>
                                    <p><b>Заявка создана.</b> Информация отправлена на ваш Email.</p>
                                </div>) : (exchangeError && (
                                <div className={'bg-white text-black p-5 rounded-2xl mt-5 flex items-center gap-5'}>
                                    <box-icon name='block' animation='tada' color='#fd4927'></box-icon>
                                    <p><b>Ошибка при обработке запроса.</b> {exchangeError}</p>
                                </div>))}

                        </div>
                    )
                }

                <div className={'w-1/2'}>
                    <h1 className={'text-4xl'}>Buttim</h1>
                    <p>Меняем шило на мыло Меняем шило на мыло Меняем шило на мыло Меняем шило на мыло Меняем шило на
                        мыло
                        Меняем шило на мыло Меняем шило на мыло Меняем шило на мыло Меняем шило на мыло </p>

                    <div className={'flex gap-5 mt-10'}>
                        <div onClick={() => setAction("exchange")}
                             className={'border-b-4 font-bold cursor-pointer ' + (action === "exchange" && 'border-amber-500 text-amber-500')}>
                            Обмен
                        </div>

                        <div onClick={() => setAction("replace")}
                             className={'border-b-4 font-bold cursor-pointer ' + (action === "replace" && 'border-amber-500 text-amber-500')}>
                            Перестановка
                        </div>
                    </div>
                </div>
            </div>

        </div>);
}

export default App;
