import {useEffect, useState} from "react";

export default function MultiPathSelector({pathsContainer, setSource}) {

    const [isOpen, setIsOpen] = useState(false);
    const [path, setPath] = useState([]);

    const [country, setCountry] = useState(null);
    const [city, setCity] = useState(null);
    const [symbol, setSymbol] = useState(null);

    const StepBack = () => {
        if (path.length > 0) {
            switch (path.length) {
                case 2:
                    setCity(null)
                    break

                case 1:
                    setCountry()
                    break

                default:
                    break
            }

            path.pop()
        }
    }

    useEffect(() => {
        if (path.length === 3) {
            setSource(path.join(' '))
        } else {
            setSource(null)
        }
    }, [path]);

    return (
        <div className={'w-full relative'}>
            <div className={'p-3 bg-[#e6efff] w-full rounded-xl flex justify-between items-center cursor-pointer'}
                 onClick={() => setIsOpen(!isOpen)}>
                {
                    path.length > 0 ? (
                        <>
                        {path.map(u =>
                            <>{u} </>
                        )}
                        </>
                    ) : (
                        <>Выберите валюту</>
                    )}
            </div>
            {
                isOpen && (
                    <div
                        className={'absolute pt-3 pb-3 z-40 bg-[#c4d9ff] w-full rounded-xl flex-col gap-3 justify-between items-center'}>
                        {
                            path.length > 0 && (
                                <p className={'cursor-pointer p-3 m-1 rounded-xl hover:bg-white transition-all font-bold'} onClick={() => StepBack()}>Назад</p>
                            )
                        }
                        {
                            country ? (
                                city ? (
                                    <>
                                        <p className={'p-3 m-1 font-bold'}>Выберите доступную валюту</p>
                                        {
                                            pathsContainer[country].currencies.map((c) =>
                                                <p onClick={() => {
                                                    setSymbol(c)
                                                    setPath([...path, c])

                                                    setIsOpen(false)
                                                }}

                                                className={'cursor-pointer p-3 m-1 rounded-xl hover:bg-white transition-all'}>{c}</p>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        <p className={'p-3 m-1 font-bold'}>Выберите доступный город</p>
                                        {
                                            pathsContainer[country].cities.map((c) =>
                                                <p onClick={() => {
                                                    setCity(c)
                                                    setPath([...path, c])
                                                }}

                                                   className={'cursor-pointer p-3 m-1 rounded-xl hover:bg-white transition-all'}>{c}</p>
                                            )
                                        }
                                    </>
                                )
                            ) : (
                                <>
                                    <p className={'p-3 m-1 font-bold'}>Выберите доступную страну</p>
                                    {Object.keys(pathsContainer).map((key) =>
                                        <p className={'cursor-pointer p-3 m-1 rounded-xl hover:bg-white transition-all'}
                                           onClick={() => {
                                               setCountry(key)
                                               setPath([...path, key])
                                           }}>{key}</p>
                                    )}
                                </>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}